import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgHeaderImage from '../images/top_whyvisit.jpg'

const IndexPage = () => {

  return (
    <Layout pageTitle="Why Visit?" headerImage={imgHeaderImage}>
      <SEO title="Why Visit?" />
      <div>
        <h2 className="section-title">Why Visit the Dentist?</h2>
      </div>
      <div className="new-row">
        <div className="two-col-1">
          <h3 className="info-title">Prevention</h3>
          <p className="general-text">
            At Pipers Dental Practice we believe that one of the most important services we can provide is <a href="/lightbox/healthyeating" className="lightbox">preventative oral care</a>. Tooth decay and <a href="/lightbox/gumdisease" className="lightbox">gum disease</a> are common problems that, for most people, are largely preventable.
            Patients presenting with tooth decay are treated with first class restorative dentistry, intended to help prevent the need for further treatment for as long as possible. But we want to help our patients avoid the problems. <a href="/lightbox/gumdisease" className="lightbox">Gum disease</a> in particular may go unnoticed by sufferers until quite advanced and is the main cause of tooth loss in UK adults.
          </p>
          <p className="general-text">
            You need to care for your teeth and gums properly if you want to retain natural teeth throughout your life and we can help.
            As a Pipers patient you will routinely receive preventative care through your regular visits to our dentists.
          </p>
          <br/>
          <h3 className="info-title">Regular dental examinations</h3>
          <p className="general-text">
            An appointment for a <a href="/lightbox/dentalexamination" className="lightbox">checkup</a> should not be missed as it provides a regular opportunity for you to be screened for <a href="/lightbox/mouthcancer" className="lightbox">oral cancer</a> and for your dentist to look for any other potential problems before they become significant. In a <a href="/lightbox/dentalexamination" className="lightbox">dental examination</a> any signs of tooth decay or <a href="/lightbox/gumdisease" className="lightbox">gum disease</a> can be identified and at this stage the remedial action required may be quite minor. Perhaps just a change of <a href="/lightbox/healthyeating" className="lightbox">diet</a> or more careful <a href="/lightbox/brushyourteeth" className="lightbox">cleaning</a> would be enough to prevent the need for surgical treatment which might be required later if the problem went undetected. In the long run a regular <a href="/lightbox/dentalexamination" className="lightbox">checkup</a> could not only save you the expense of further more complex treatment but it could also prevent you from losing your teeth.
          </p>
          <p className="general-text">
            Visiting the dentist regularly helps to keep your mouth healthy. If ignored some common problems could go undetected or arise. These include:
          </p>
          <ul className="inline-list">
            <li><a href="/lightbox/badbreath" className="lightbox">Bad breath</a> and Plaque</li>
            <li>Tooth decay</li>
            <li><a href="/lightbox/gumdisease" className="lightbox">Gum or Periodontal disease</a></li>
            <li>Oral disease such as ulcers as well as <a href="/lightbox/mouthcancer" className="lightbox">oral cancer</a></li>
          </ul>
          <p className="general-text  new-row">In instances when problems do occur, we offer our patients effective treatments to restore their dental health.</p>
        </div>
        <div className="two-col-2">
          <h3 className="info-title">Regular hygiene visits</h3>
          <p className="general-text">
            Our dentists also have an important role to play in helping you maintain healthy teeth and gums. They will provide high quality care tailored to your individual needs and give you a professional <a href="/lightbox/scalingandpolishing" className="lightbox">scale and polish</a>, ensuring that the plaque is removed from all surfaces of your teeth. This is especially important because plaque contains the bacteria that cause <a href="/lightbox/gumdisease" className="lightbox">gum disease</a> and <a href="/lightbox/badbreath" className="lightbox">bad breath</a>.
            After this your whole mouth will feel clean and your breath fresh. Regular visits and advice will help you build confidence in maintaining an effective oral care regime and achieving a healthy mouth. Our dentists will also provide dietary advice to keep your teeth healthier for longer.
            There is mounting evidence demonstrating a link between good oral health care and good overall health.
          </p>
          <p className="bold general-text">Here are our top tips for healthy teeth and gums:</p>
          <ul id="top-tips" className="general-text">
            <li>Have a regular <a href="/lightbox/dentalexamination" className="lightbox">dental checkup</a></li>
            <li>Have regular <a href="/lightbox/scalingandpolishing" className="lightbox">hygiene visit</a></li>
            <li><a href="/lightbox/brushyourteeth" className="lightbox">Clean your teeth</a> regularly each day</li>
            <li>Develop a good <a href="/lightbox/brushyourteeth" className="lightbox">brushing technique</a> &mdash; clean all surfaces effectively</li>
            <li>Use dental floss to clean between teeth</li>
            <li>Use <a href="/lightbox/flouride" className="lightbox">fluoride</a> toothpaste or mouthwash</li>
            <li>Avoid <a href="/lightbox/tootherosion" className="lightbox">food and drink with excess sugar</a></li>
            <li>Avoid <a href="/lightbox/healthyeating" className="lightbox">Healthy eating</a></li>
          </ul>
          <p className="general-text">All the team at Pipers are committed to helping our patients maintain their oral health to minimise the need for <a href="/lightbox/extraction" className="lightbox">extractions</a> or restorative dental treatments. Remember healthy teeth and gums benefit your overall health and well-being.</p>
        </div>
      </div>
    </Layout>
  )
}



export default IndexPage
